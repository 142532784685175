<template>
  <div class="page-user-generate">
    <UserGenerateChart />
  </div>
</template>
<script>
import { defineComponent } from 'vue'
import UserGenerateChart from '@/views/stats/user-generate-chart.vue'

export default defineComponent({
  name: 'UserGenerate',
  components: { UserGenerateChart },
  setup() {
    return {}
  }
})
</script>
<style lang="scss">
.page-user-generate {
  position: relative;
}
</style>
